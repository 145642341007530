import React from 'react'
import { Menu, Footer, useLanguage, BaseLayout, withLanguageProvider, MainLayout } from '@tmp'
import { graphql } from 'gatsby'

import cn from 'classnames'
// import shuffle from 'lodash/shuffle'
// import { BookMeetingSection } from '@src/components'
// import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import BookMeetingSteps from '@src/components/BookMeetingSteps'

// const colors = shuffle([
//   'text-white bg-violette-400',
//   'text-white bg-red-400',
//   'text-dark-400 bg-orange-400',
//   'text-dark-400 bg-yellow-400',
// ])

// let currentIndex = 0
// const getRandomColor = () => colors[currentIndex++ % colors.length]

// const color = getRandomColor()

// const tempImg: IGatsbyImageData = {
//   "images": {
//       "sources": [
//           {
//               "srcSet": "//images.ctfassets.net/nl35sbcspsvu/61RKbpxwYyOeZdh96X84ig/aa3386873e98c31c5ecf4caad1eec8de/Harpa_600x400.jpg?w=750&h=493&q=85&fm=webp 750w,\n//images.ctfassets.net/nl35sbcspsvu/61RKbpxwYyOeZdh96X84ig/aa3386873e98c31c5ecf4caad1eec8de/Harpa_600x400.jpg?w=1080&h=709&q=85&fm=webp 1080w,\n//images.ctfassets.net/nl35sbcspsvu/61RKbpxwYyOeZdh96X84ig/aa3386873e98c31c5ecf4caad1eec8de/Harpa_600x400.jpg?w=1200&h=788&q=85&fm=webp 1200w",
//               "sizes": "100vw",
//               "type": "image/webp"
//           }
//       ],
//       "fallback": {
//           "src": "//images.ctfassets.net/nl35sbcspsvu/61RKbpxwYyOeZdh96X84ig/aa3386873e98c31c5ecf4caad1eec8de/Harpa_600x400.jpg?w=1200&h=788&fl=progressive&q=85&fm=jpg",
//           "srcSet": "//images.ctfassets.net/nl35sbcspsvu/61RKbpxwYyOeZdh96X84ig/aa3386873e98c31c5ecf4caad1eec8de/Harpa_600x400.jpg?w=750&h=493&fl=progressive&q=85&fm=jpg 750w,\n//images.ctfassets.net/nl35sbcspsvu/61RKbpxwYyOeZdh96X84ig/aa3386873e98c31c5ecf4caad1eec8de/Harpa_600x400.jpg?w=1080&h=709&fl=progressive&q=85&fm=jpg 1080w,\n//images.ctfassets.net/nl35sbcspsvu/61RKbpxwYyOeZdh96X84ig/aa3386873e98c31c5ecf4caad1eec8de/Harpa_600x400.jpg?w=1200&h=788&fl=progressive&q=85&fm=jpg 1200w",
//           "sizes": "100vw"
//       }
//   },
//   "layout": "fullWidth",
//   "width": 1,
//   "height": 0.6566666666666667,
//   "placeholder": {
//       "fallback": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAZABkAAD/2wBDAAQDAwQDAwQEBAQFBQQFBwsHBwYGBw4KCggLEA4RERAOEA8SFBoWEhMYEw8QFh8XGBsbHR0dERYgIh8cIhocHRz/2wBDAQUFBQcGBw0HBw0cEhASHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBz/wAARCAANABQDAREAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABwb/xAAlEAABAwMDAwUAAAAAAAAAAAABAgMEBRESACFBBgciEzJRYaH/xAAYAQADAQEAAAAAAAAAAAAAAAAEBQcGAv/EACMRAAEDAwQCAwAAAAAAAAAAAAEAAgMEESEFEjFRBhMikfH/2gAMAwEAAhEDEQA/AF+bBeZodTdgrZamojOGOp4eCXMTiVfV7ahOlUw9jfaMEhUObc4bY+UVdsaDKp1cdbPUtTqLa6a2ufFqWS8ZpIJU0s8WuCBtunc8bDyVlMadhhYAb8jGOigKKlqYHOMpNuM99hIjsdIWdvzU7c9wKbgqxXT2nIzqXPNCkkFJ5FtPYhtcHDkIASEG4Q32yWJk6W+vIrKAW8iD6aFH27AXtYb86cay8uiAGBuI+v1GSyuPxKVTEQbEnj41mnNF1wHYX//Z"
//   }
// }

const PageTemplate = () => {
  const { t, locale } = useLanguage()
  return (
    <>
      <main className={cn('relative pb-40 transition-colors duration-1000')}>
        <Menu />
        <div className=''>
          <BookMeetingSteps t={t} locale={locale} />
        </div>
      </main>
      <Footer className='-mt-40 bg-white pt-50 footer-container-clip' />
    </>
  )
}

export default withLanguageProvider(PageTemplate, 'is')
